.App {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  background-color: #243a62;
  color: aliceblue;
}

.img {
  background-image: url("../public/bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 25vh;
  width: 100vw;
}

.header {
  font-size: 4em;
}

.main {
  text-align: center;
}

.footer {
  margin-block-start: 1.7em;
  margin-block-end: 2.7em;
}
